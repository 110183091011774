import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import get from 'lodash/get';
import loadable from '@loadable/component'

import Seo from '../components/seo';
import Header from '../components/Header';
import Footer from '../components/footer';
import Blocks from '../components/blocks';
import Layout from '../components/layout';

import NewArticles from '../components/BlockLibrary/Healthzone/NewArticles';
// import FeatureCategories from '../components/BlockLibrary/Healthzone/FeatureCategories';
// import BrowserContentByCategory from '../components/BlockLibrary/Healthzone/BrowserContentByCategory';

const BrowserContentByCategory = loadable(() => import(`../components/BlockLibrary/Healthzone/BrowserContentByCategory`));

import PgHome from '../components/BlockLibrary/PetGazette/PgHome';
import '../sass/main.scss';
import ContentfulHamboneNews from '../components/BlockLibrary/HamboneAward/ContentfulHamboneNews';;
import ContentfulWackyNews from '../components/BlockLibrary/ContentfulWackyNew';
class PageTemplate extends React.Component {
     componentDidMount() {
          const brower = typeof window !== `undefined`;
          const states = get(this.props, 'data.allContentfulStates.edges');
          this.props.setStates(states);

          const statesClaimFile = get(this.props, 'data.allContentfulBlockForm.edges');
          this.props.setStateClaimFile(statesClaimFile);

          if (brower) {
               var getOmValue = localStorage.getItem('Petinsurance_OM');
               this.props.setTrackingCode(getOmValue);

               var getPhoneValue = localStorage.getItem('Petinsurance_TrackingPhone');
               this.props.setTrackingPhone(getPhoneValue);
          }
     }

     render() {
          const page = get(this.props, 'data.contentfulPage');
          const path = get(this.props, '*');

          const getComponentMain = () => {
               if (!page) return <></>;
               switch (page.slug) {
                    case 'healthzone':
                         return (
                              <>
                                   <NewArticles></NewArticles>
                                   {page.rows && <Blocks blocks={page.rows}></Blocks>}
                                   {/* <FeatureCategories></FeatureCategories> */}
                                   <BrowserContentByCategory></BrowserContentByCategory>
                              </>
                         );
                    case 'pet-gazette':
                         return <PgHome />;
                    case 'hamboneaward/news':
                         return (
                              <>
                                   {page.rows && <Blocks blocks={[page.rows[0]]}></Blocks>}
                                   <ContentfulHamboneNews />
                                   {page.rows && <Blocks blocks={[page.rows[1]]}></Blocks>}
                              </>
                         );
                    // case 'hamboneaward':
                    //      return <HamboneAwardHome page={page} />;
                    case 'wacky-blog':
                         return (
                              <>
                                   {page.rows && <Blocks blocks={page.rows}></Blocks>}
                                   <ContentfulWackyNews />
                              </>
                         );
                    default:
                         let stringRows = JSON.stringify(page.rows);

                         if (stringRows.includes('##STATE##') && page.contentName) {
                              stringRows = stringRows.replace(/##STATE##/g, page.contentName);
                              page.rows = JSON.parse(stringRows);
                         }

                         return <>{page.rows && <Blocks blocks={page.rows}></Blocks>}</>;
               }
          };

          return (
               <Layout>
                    <Seo
                         pageName={page.omniturePageNameLegacy || page.contentName}
                         specificPhone={page.specificPhone}
                         trackingCode={page.trackingCode}
                         title={page.seoTitleTag?.includes('##STATE##') ? page.seoTitleTag.replace(/##STATE##/g, page.contentName) : page.seoTitleTag}
                         path={path}
                         canonical={page.seoCanonicalUrl ? page.seoCanonicalUrl.seoCanonicalUrl : ''}
                         description={page.seoMetaDescription ? page.seoMetaDescription.seoMetaDescription : ''}
                         robots={page.robotsDirective ? page.robotsDirective.robotsDirective : ''}
                         image={page.socialImage}
                         googleSiteVerificationContent={page.googleSiteVerificationContent}
                         showAntiFlickerSnippet={page.showAntiFlickerSnippet}
                    />
                    {page.header && <Header header={page.header} specificPhone={page.specificPhone}></Header>}
                    <main id="main-content" role="main">
                         {page.seoH1 && <h1 style={{ display: 'none' }}>{page.seoH1}</h1>}
                         {getComponentMain()}
                    </main>
                    {page.footer && <Footer specificPhone={page.specificPhone} footer={page.footer}></Footer>}
               </Layout>
          );
     }
}

const mapDispatchToProps = (dispatch) => {
     return {
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone }),
          setStateClaimFile: (file) => dispatch({ type: 'STATEFILE_LOADED', payload: file }),
          setStates: (states) => dispatch({ type: 'STATE_LOADED', payload: states })
     };
};

const mapStateToProps = (state) => {
     return {
          state
     };
};
export default connect(mapStateToProps, mapDispatchToProps)(PageTemplate);

export const pageQuery = graphql`
     query PageBySlug($slug: String!) {
          site {
               siteMetadata {
                    title
               }
          }
          contentfulPage(slug: { eq: $slug }) {
               ...ContentfulPageFields
          }
     }
`;
